$primary-color: var(--bs-primary);

.nav-pills.subnav {
  border-bottom: 1px solid #ccc;
  .nav-link {
    color: #696969;
    padding: 0.3rem 0.8rem;

    &.active {
      color: $primary-color;
      background-color: transparent;
      position: relative;
      &::after {
        display: block;
        position: absolute;
        content: ' ';
        background-color: $primary-color;
        width: 100%;
        height: 3px;
        left: 0;
        bottom: -1px;
      }
    }
  }
}
