body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  display: block;
  font-size: 14px;
}

body {
  font: 14px/1.6 -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC,
    Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
}

* {
  box-sizing: border-box;
  word-break: break-all;
}

a {
  text-decoration: none;
  color: var(--dmp-brand-color);
}

.sticky {
  position: fixed;
}

.danger-zone {
  background-color: #fef0f0;
  border-radius: 0.3rem;
  padding: 1.5rem;
  border: solid 1px #fee2e3;
  .danger-zone-content {
    & > h4 {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: var(--dmp-text-color-title-secondary);
      line-height: 22px;
    }
    & > div {
      font-size: 12px;
      font-weight: 400;
      color: #777e8c;
      line-height: 17px;
    }
  }
}

.full-screen-mask {
  background-color: #f4f4f6;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
}

.color-required {
  color: #ff0048 !important;
}

.link-action {
  text-decoration: none;
  color: var(--dmp-brand-color);
  cursor: pointer;
}

.table.table-dmp {
  > :not(:last-child) > :last-child > * {
    border-bottom: 2px solid #ccc;
  }

  > :not(caption) > * > * {
    padding: 0.8rem 0.5rem;
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@mixin box-shadow {
  box-shadow: 0px 2px 4px 0px #eff1fa;
}

#root {
  height: 100vh;
  color: var(--dmp-text-color);
}

.layout-body {
  display: flex;
  min-height: 100%;
  flex: 1;
  .layout-content {
    position: relative;
    flex: 1;
    height: auto;
    background-color: #f9f9f9;
    overflow: auto;
  }
}

.moduler {
  background: var(--dmp-default-color);
  margin-bottom: 16px;
  position: relative;
  min-width: 1130px;
  padding: 32px 24px 48px 32px;
  border-radius: 4px;
  @include box-shadow();
}

.nav.moduler-tabs {
  .nav-item .nav-link:not(.active) {
    background-color: #f3f4f5;
  }
  .nav-item + .nav-item {
    margin-left: 0.5rem;
  }
}

.moduler-container {
  margin: 12px;
}

.toolbox-container {
  padding: 12px;
  overflow: auto;
  width: 100%;
  .toolbox-moduler {
    border: none;
    box-shadow: none;
  }
}

.nav-text-title {
  @include box-shadow();
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: var(--dmp-default-color);
  font-size: 0;

  span {
    // font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    margin-left: 32px;
  }
}

.ad-row-title {
  font-size: 22px;
  margin-bottom: 32px;
}

.package-header {
  @include box-shadow();
  height: 70px;
  line-height: 70px;
  background-color: var(--dmp-default-color);
  border: 1px solid #eaeaea;

  .package-title {
    font-size: 22px;
    font-weight: 500;
    color: #333;
    margin-left: 60px;
    position: relative;
    &::after {
      display: block;
      position: absolute;
      content: ' ';
      font-size: 0;
      background: #324bff;
      border-radius: 2px;
      width: 4px;
      height: 20px;
      left: -12px;
      top: 50%;
      margin-top: -10px;
    }
  }
}

.package-footer {
  @include box-shadow();
  margin: 0 12px;
  height: 66px;
  background: var(--dmp-default-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.arrow {
  display: inline-block;
  font-family: 'iconfont' !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  &::before {
    content: '\e743';
  }
  &.down {
    transform: rotate(180deg);
  }
  &.left {
    transform: rotate(-90deg);
  }
  &.right {
    transform: rotate(90deg);
  }
}

.text-tips {
  font-size: 14px;
  color: var(--dmp-text-color-secondary);
}

.border-brand-light {
  border-color: var(--dmp-brand-light-color);
}

@each $type, $value in $dmp-theme-colors {
  .text-#{$type} {
    color: $value !important;
  }
  .bg-#{$type} {
    background-color: $value !important;
  }
}
