.basic-login-form {
  .form-label {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #666666;
    line-height: 22px;
    &::before {
      content: '*';
      color: #ff0000;
      margin-right: 4px;
    }
  }
}
