.dmp-system-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--dmp-text-color-menu);
  text-decoration: none;
  & > img {
    width: 83px;
    height: 22px;
  }
  &:hover {
    color: var(--dmp-brand-color);
  }
}

.dmp-custom__menu .t-menu {
  .t-menu__item {
    color: var(--dmp-text-color-menu);
    &:hover:not(.t-is-active):not(.t-is-opened):not(.t-is-disabled) {
      background-color: transparent;
      color: var(--dmp-brand-color);
    }
    &.t-is-active {
      background-color: transparent;
      color: var(--dmp-brand-color);
    }
  }
}
