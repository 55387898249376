.estimate-modal__layout {
  position: fixed;
  width: 180px;
  right: 40px;
  top: 60%;
  background-color: var(--dmp-brand-color-hover);
  border: 1px solid var(--dmp-brand-light-color);
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px var(--dmp-brand-color-hover);
  padding: 20px 20px 0;
  z-index: 10;
  .estimate-modal__line {
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      font-weight: 700;
      color: var(--dmp-text-color-table);
      line-height: 22px;
      margin-bottom: 6px;
    }
    .estimate-number {
      font-size: 18px;
      font-family: Arial, Arial-Regular;
      font-weight: 400;
      color: var(--dmp-brand-color);
      line-height: 21px;
    }
  }
}
