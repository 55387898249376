.login-page-aside {
  width: 300px;
  height: 460px;
  background: #3e82f7;
  border-radius: 6px 0px 0px 6px;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('../../assets/login/login-aside.png');
    background-repeat: no-repeat;
    background-position: center;
  }
}
.login-page-form {
  width: 580px;
  height: 460px;
  background: var(--dmp-default-color);
  border-radius: 0px 6px 6px 6px 0px;
  .title {
    font-size: 32px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #333333;
    line-height: 45px;
  }
}
