.list-group-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.list-group-checkable {
  display: grid;
  gap: 0.5rem;
  border: 0;
}
// .list-group {
//   width: auto;
//   max-width: 460px;
//   margin: 4rem auto;
// }
.list-group-checkable .list-group-item {
  cursor: pointer;
  border-radius: 0.5rem;
}
.list-group-item-check:checked + .list-group-item {
  color: var(--dmp-default-color);
  background-color: var(--bs-blue);
}
.offcanvas-backdrop {
  pointer-events: none;
}

.btn:focus {
  box-shadow: none !important;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--dmp-text-color-secondary);
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--dmp-text-color-secondary);
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--dmp-text-color-secondary);
}

.form-control,
.form-control.is-invalid {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-select:focus {
  outline: none;
  box-shadow: none;
}
