$indicator-color: blue;
$border-readonly-color: var(--bs-gray-600, #ddd);

.targeting-lines {
  position: relative;

  // 大尺寸模式
  &.targeting-lg {
    .targeting-line .line-title {
      width: 150px;
    }
    .targeting-options {
      > li {
        padding: 0.4rem 0.8rem;
      }
    }
  }
  &.targeting-sm {
    .targeting-line .line-title {
      width: 100px;
    }
    .targeting-options {
      > li {
        padding: 0.2rem 0.4rem;
      }
    }
  }
}

.targeting-line {
  display: flex;
  .line-title {
    margin-top: 6px;
    width: 120px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: var(--dmp-text-color-table);
    line-height: 20px;
  }
  .line-component {
    flex: 1;
  }
}

.targeting-options {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  > li {
    border: 1px solid var(--dmp-divide-color);
    padding: 0.3rem 0.6rem;
    user-select: none;
    margin-bottom: 6px;
    color: var(--dmp-text-color-table);
    cursor: pointer;
    &:first-child {
      border-radius: 0.3rem 0 0 0.3rem;
    }
    &:last-child {
      border-radius: 0 0.3rem 0.3rem 0;
    }
    &:not(:last-child) {
      border-right-width: 0px;
    }

    &:hover,
    &.selected {
      color: var(--dmp-default-color);
      background-color: var(--dmp-brand-color);
    }
  }

  &.multi-select > li {
    position: relative;
    &::after {
      display: block;
      position: absolute;
      content: ' ';
      border: 3px solid #bababa;
      border-color: #bababa #bababa transparent transparent;
      width: 0;
      height: 0;
      top: 2px;
      right: 2px;
    }

    &.selected::after {
      border-color: $indicator-color $indicator-color transparent transparent;
    }

    &.option-default::after {
      display: none;
    }
  }

  &.is-readonly > li,
  &.multi-select.is-readonly > li {
    background-color: var(--dmp-module-divide-color);
    border-color: var(--dmp-divide-color);
    color: var(--dmp-text-color-table);
    &.selected {
      border-color: var(--dmp-text-color-secondary);
      background-color: var(--dmp-divide-color);
      color: var(--dmp-text-color-table);
      &::before {
        border-left-color: var(--dmp-divide-color);
      }
      & + li {
        border-left-color: var(--dmp-text-color-secondary);
      }
    }
  }
}
