.create-segment-dialog__list {
  padding: 4px;
  .t-list-item {
    cursor: pointer;
    height: 94px;
    background: var(--dmp-default-color);
    border-radius: 8px;
    box-shadow: 0px 1px 4px 2px var(--dmp-hover-bg-brand);
    margin-bottom: 10px;
    border: solid 1px var(--dmp-default-color);
    &:hover,
    &.active {
      border-color: var(--dmp-brand-color);
      background-color: var(--dmp-brand-color-hover);
    }
  }

  .t-list-item__meta-title {
    margin-bottom: 12px;
  }
  .t-list-item__meta-avatar {
    width: 48px;
    height: 48px;
    border-radius: 0;
    background-color: transparent;
  }
  .t-list-item__meta-description {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: var(--dmp-text-color-secondary);
    line-height: 17px;
    & > p {
      margin-bottom: 0;
    }
  }
}
