.insight-segment {
  .insight-segment_attribute-type__wrapper {
    background-color: var(--dmp-module-divide-color);
    padding-bottom: 20px;
  }
  .insight-segment_attribute-charts__wrapper {
    background-color: var(--dmp-module-divide-color);
    .t-col {
      margin-bottom: 20px;
    }
  }
}
