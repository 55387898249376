.member-item-wrapper {
  .dmp-transfer__remove-icon {
    visibility: hidden;
    font-size: 16px;
    color: var(--dmp-brand-color);
  }
  &:hover {
    background-color: var(--dmp-hover-bg-brand);
    color: var(--dmp-brand-color);
    .dmp-transfer__remove-icon {
      visibility: visible;
    }
  }
}
