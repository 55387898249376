.dmp-workspace__layout {
  height: calc(100vh - 64px);
  padding-top: 1px;
  .dmp-workspace-layout__menus {
    .menu-item {
      color: var(--dmp-text-color);
      border-radius: 4px;
      a {
        color: var(--dmp-text-color);
      }
      &.active,
      &:hover {
        color: var(--dmp-brand-color);
        background-color: var(--dmp-brand-color-hover);
        border-right: solid 2px var(--dmp-brand-color);
      }
    }
  }
  .dmp-workspace-layout__content {
    width: calc(100vw - 232px);
    height: 100%;
    .dmp-workspace__content {
      padding: 2px 20px 20px;
      background-color: var(--dmp-module-divide-color);
    }
  }
}
