.expand-segment-detail__layout {
  padding: 10px 20px;
  background-color: var(--dmp-hover-bg-brand);
  max-height: 400px;
  overflow-y: auto;
  font-size: 14px;
  color: var(--dmp-text-color-table);
  line-height: 20px;
  border: 1px solid var(--dmp-brand-light-color);
  border-radius: 4px;
  & > div {
    margin-bottom: 10px;
  }
  label {
    width: 90px;
    margin-right: 10px;
  }
}
