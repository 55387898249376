.geo-location-picker {
  .list-group {
    margin-left: -1px;
    &.level-1 {
      margin-left: 0;
    }
  }
  .list-group-item.bg-light {
    background-color: #f0f2f5 !important;
  }
}
