.base-page__layout {
  .base-page__title {
    font-size: 24px;
    font-weight: 700;
    color: var(--text-color-title);
  }
}

.workspace-page__layout {
  min-width: 100%;
  min-height: 100%;
  background-color: var(--dmp-default-color);
  .workspace-page__layout__top {
    width: calc(100% + 38px);
    margin-left: -18px;
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #777e8c;
    line-height: 20px;
    .text-brand {
      font-size: 18px;
      font-family: PingFang, PingFang-Bold;
      font-weight: 700;
      color: #1890ff;
    }
  }
  .workspace__page__header {
    background-color: var(--dmp-module-divide-color);
  }

  .workspace-page__header__children {
    background-color: var(--dmp-module-divide-color);
  }

  .workspace-page__title {
    font-size: 24px;
    font-weight: 700;
    color: var(--text-color-title);
  }
  .workspace-page__back {
    cursor: pointer;
    color: var(--dmp-brand-color);
    font-size: 12px;
    font-weight: normal;
    padding-right: 13px;
    margin-right: 13px;
    border-right: solid 2px var(--dmp-text-color-secondary);
  }
  .workspace-container__bg {
    background-color: var(--dmp-default-color);
  }
}

.workspace-module__layout {
  background-color: var(--dmp-module-divide-color);
  .workspace-module {
    padding: 20px;
    border-radius: 4px;
    background-color: var(--dmp-default-color);
    .workspace-module-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 25px;
    }
    .workspace-module-title-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 4px;
      height: 20px;
      background-color: var(--dmp-brand-color);
    }
  }

  .workspace-module-gap {
    border-bottom: solid 20px var(--dmp-module-divide-color);
  }
}
